<template>
  <div>
    <b-card title="Grafik Penjualan">
      <div class="row mb-1">
        <div class="col-md-12">
          <div class="float-left transaction-filter">
            <v-select
              v-model="currentObject.book_ids"
              :options="books"
              :reduce="val => val.value"
              :clearable="false"
              input-id="users"
              placeholder="Filter buku"
              multiple
            />
          </div>
          <div class="ml-1 float-left">
            <b-form-datepicker
              v-model="currentObject.start_date"
              placeholder="Tgl Transaksi awal"
              class="form-control date-filter"
              id="start_date"
              :clearable="true"
              :max="currentObject.end_date"
              locale="id"
            />
          </div>
          <div class="ml-1 float-left">
            <b-form-datepicker
              v-model="currentObject.end_date"
              placeholder="Tgl Transaksi akhir"
              class="form-control date-filter"
              id="end_date"
              :clearable="true"
              :min="currentObject.start_date"
              locale="id"
            />
          </div>
          <div class="ml-1 float-left">
            <b-button @click="fetchData" variant="primary" :disabled="isLoading">{{ (isLoading) ? "Tunggu..." : "Filter" }}</b-button>
          </div>
          <div class="ml-1 float-left">
            <b-button @click="downloadExcel" variant="success" :disabled="isLoading || excelData.length == 0">{{ (isLoading) ? "Tunggu..." : "Export Excel" }}</b-button>
          </div>
        </div>
      </div>

      <div class="row mb-1 mt-1">
        <div class="col-md-12">
          <div v-if="isLoading || !series" class="text-center mt-1 mb-1">
            <b-spinner label="Tunggu..."></b-spinner>
          </div>
          <div v-else>
            <!-- <div class="mb-1 ml-2">
              <h5 class="mt-1 mb-1">Total Pendapatan: Rp {{ formatPrice(earnings) }}</h5>
              <h5 class="mb-1">Total Unit Terjual: {{ formatPrice(totalUnits) }}</h5>
            </div> -->

            <vue-apex-charts
              type="bar"
              height="480"
              :options="chartOptions"
              :series="series"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h4 class="mt-1 mb-1">Data Penjualan</h4>
          <div v-if="!bookSales || isLoading" class="text-center mt-1 mb-1">
            <b-spinner label="Tunggu..."></b-spinner>
          </div>
          <div v-else>
            <vue-good-table
              v-if="bookSales && !isLoading"
              :columns="columns"
              :rows="bookSales"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Row number -->
                <span
                  v-if="props.column.field === 'row_number'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
                </span>

                <span
                  v-if="props.column.field === 'total_units_formatted'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ formatPrice(props.row.total_units) }}</span>
                </span>

                <span
                  v-if="props.column.field === 'total_price_formatted'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ formatPrice(props.row.total_price) }}</span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Data 1 hingga
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> dari {{ props.total }} </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>

        <div class="col-md-6">
          <h4 class="mt-1 mb-1">Statistik Penjualan</h4>
          <div v-if="isLoading" class="text-center mt-1 mb-1">
            <b-spinner label="Tunggu..."></b-spinner>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-md-6">
                <b-card>
                  <div class="text-center">
                    <h5 class="mb-1">Transaksi Sukses</h5>
                    <h4 class="mb-1">{{ formatPrice(totalSuccessTransactions) }}</h4>
                  </div>
                </b-card>
              </div>

              <div class="col-md-6">
                <b-card>
                  <div class="text-center">
                    <h5 class="mb-1">Transaksi Menunggu Pembayaran</h5>
                    <h4 class="mb-1">{{ formatPrice(totalWaitingTransactions) }}</h4>
                  </div>
                </b-card>
              </div>

              <div class="col-md-6">
                <b-card>
                  <div class="text-center">
                    <h5 class="mb-1">Transaksi Gagal</h5>
                    <h4 class="mb-1">{{ formatPrice(totalFailedTransactions) }}</h4>
                  </div>
                </b-card>
              </div>

              <div class="col-md-6">
                <b-card>
                  <div class="text-center">
                    <h5 class="mb-1">Total Pendapatan</h5>
                    <h4 class="mb-1">Rp {{ formatPrice(earnings) }}</h4>
                  </div>
                </b-card>
              </div>

              <div class="col-md-6">
                <b-card>
                  <div class="text-center">
                    <h5 class="mb-1">Total Unit Terjual</h5>
                    <h4 class="mb-1">{{ formatPrice(totalUnits) }}</h4>
                  </div>
                </b-card>
              </div>

            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card title="Laporan Voucher">
      <div class="row mb-1">
        <div class="col-md-12">
          <div class="float-left">
            <v-select
              v-model="voucherObject.status"
              :options="voucherFilterValues"
              :clearable="false"
              :reduce="val => val.value"
              input-id="users"
              placeholder="Filter status"
            />
          </div>
          <div class="ml-1 float-left">
            <v-select
              v-model="voucherObject.category"
              :options="voucherCategories"
              :reduce="val => val.value"
              :clearable="false"
              placeholder="Filter tagging"
            />
          </div>
          <div class="ml-1 float-left">
            <b-form-input
              v-model="voucherObject.search"
              placeholder="Prefix voucher (misal: ABCD)"
              maxlength="4"
            />
          </div>
          <div class="ml-1 float-left">
            <b-button @click="fetchVoucherData" variant="primary" :disabled="isLoadingVoucher">{{ (isLoadingVoucher) ? "Tunggu..." : "Filter" }}</b-button>
          </div>
          <div class="ml-1 float-left">
            <b-button @click="downloadExcelVoucher" variant="success" :disabled="isLoadingVoucher || voucherData.rows.length == 0">{{ (isLoadingVoucher) ? "Tunggu..." : "Export Excel" }}</b-button>
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-md-8">
          <div v-if="isLoadingVoucher" class="text-center mt-1 mb-1">
            <b-spinner label="Tunggu..."></b-spinner>
          </div>
          <voucher-table v-else :rows="voucherData.rows"></voucher-table>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-6">
              <b-card>
                <div class="text-center">
                  <h5 class="mb-1">Total Voucher</h5>
                  <h4 class="mb-1">{{ formatPrice(voucherData.total_all_vouchers) }}</h4>
                </div>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card>
                <div class="text-center">
                  <h5 class="mb-1">Total Voucher Terjual</h5>
                  <h4 class="mb-1">{{ formatPrice(voucherData.total_sold) }}</h4>
                </div>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card>
                <div class="text-center">
                  <h5 class="mb-1">Total Voucher Belum Terjual</h5>
                  <h4 class="mb-1">{{ formatPrice(voucherData.total_unsold) }}</h4>
                </div>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card>
                <div class="text-center">
                  <h5 class="mb-1">Total Voucher Dipakai</h5>
                  <h4 class="mb-1">{{ formatPrice(voucherData.total_used) }}</h4>
                </div>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card>
                <div class="text-center">
                  <h5 class="mb-1">Total Voucher Belum Dipakai</h5>
                  <h4 class="mb-1">{{ formatPrice(voucherData.total_unused) }}</h4>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import VoucherTable from './VoucherTable.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    XLSX,
    VueApexCharts,
    BFormDatepicker,
    VoucherTable,
  },
  data() {
    return {
      voucherCategories: [
        {
          label: "Semua Tagging",
          value: "all"
        },
      ],
      voucherFilterValues: [
        {
          label: "Semua Status",
          value: "all"
        },
        {
          label: "Terjual",
          value: "sold"
        },
        {
          label: "Belum Terjual",
          value: "unsold"
        },
        {
          label: "Dipakai",
          value: "used"
        },
        {
          label: "Belum Dipakai",
          value: "unused"
        },
        {
          label: "Belum Dijual & Belum Dipakai",
          value: "unsold_unused"
        },
        {
          label: "Sudah Dijual & Dipakai",
          value: "sold_used"
        }
      ],
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number',
        },
        {
          label: 'Nama Buku',
          field: 'name',
        },
        {
          label: 'Jumlah Unit',
          field: 'total_units_formatted',
        },
        {
          label: 'Nominal Transaksi (Rp)',
          field: 'total_price_formatted',
        },
      ],
      searchTerm: '',
      series: null,
      chartOptions: {
        chart: {
          type: 'bar',
          height: 480,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        xaxis: {
          type: 'datetime',
          categories: [],
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
      isLoading: false,
      isLoadingVoucher: false,
      pageName: "Laporan",
      voucherObject: {
        status: "all",
        search: null,
        category: "all",
      },
      currentObject: {
        id: '',
        start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        book_ids: null,
      },
      books: [],
      excelData: [],
      earnings: 0,
      totalUnits: 0,
      totalSuccessTransactions: 0,
      totalFailedTransactions: 0,
      totalWaitingTransactions: 0,
      bookSales: null,
      voucherData: {
        total_all_vouchers: 0,
        total_used: 0,
        total_unused: 0,
        total_sold: 0,
        total_unsold: 0,
        rows: []
      }
    }
  },
  computed: {
  },
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    downloadExcelVoucher() {
      this.isLoadingVoucher = true

      let excelRows = []

      this.voucherData.rows.map(row => {

          let currentObj = {}

          currentObj = {
            kode_voucher: (row.voucher_code) ? row.voucher_code : '-',
            nominal_voucher: (row.voucher_value) ? this.formatPrice(row.voucher_value) : '-',
            status_dijual: (row.sold_status == 1) ? "Terjual" : 'Belum Terjual',
            status_digunakan: (row.used_status == 1) ? "Dipakai" : 'Belum Dipakai',
            tagging_voucher: (row.voucher_category) ? row.voucher_category : '-',
          }

        excelRows.push(currentObj)

      })

      let excelHeaders = []

      excelRows.forEach((row, index) => {
        for(const [objIndex, [key, value]] of Object.entries(Object.entries(row))) {

          if(index == 0) {
            const words = key.split("_");

            let colName = []

            words.forEach((word) => {
              colName.push(word[0].toUpperCase() + word.substring(1))
            })

            excelHeaders.push(colName.join(" "))
          }

          if(value == '' || value === null) {
            excelRows[index][key] = '-'
          }
        }
      })

      const worksheet = XLSX.utils.json_to_sheet(excelRows)

      const workbook = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(workbook, worksheet, "Semua Data")

      XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: "A1" });

      XLSX.writeFile(workbook, "Laporan Voucher_" + Date.now() + ".xlsx", { compression: true });

      this.isLoadingVoucher = false
    },
    downloadExcel() {
      this.isLoading = true

      let excelRows = []

      this.excelData.map(row => {

          let currentObj = {}

          currentObj = {
            tanggal_transaksi: (row.transaction_date) ? this.formatDateReverse(row.transaction_date) : '-',
            no_transaksi: (row.transaction_number) ? row.transaction_number : '-',
            nama_pengguna: (row.user_name) ? row.user_name : '-',
            email_pengguna: (row.user_email) ? row.user_email : '-',
            nama_buku: (row.name) ? row.name : '-',
            penulis_buku: (row.author) ? row.author : '-',
            harga_buku: (row.book_price) ? this.formatPrice(row.book_price) : '-',
            total_transaksi: (row.total_price) ? this.formatPrice(row.total_price) : '-',
            kode_voucher: (row.voucher_code) ? row.voucher_code : '-',
            metode_pembayaran: (row.payment_method) ? row.payment_method : '-',
            nomor_referensi_pembayaran: (row.payment_referal_number) ? row.payment_referal_number : '-',
            nominal_pembayaran_tambahan: (row.additional_payment_amount) ? this.formatPrice(row.additional_payment_amount) : '-',
          }

        excelRows.push(currentObj)

      })

      let excelHeaders = []

      excelRows.forEach((row, index) => {
        for(const [objIndex, [key, value]] of Object.entries(Object.entries(row))) {

          if(index == 0) {
            const words = key.split("_");

            let colName = []

            words.forEach((word) => {
              colName.push(word[0].toUpperCase() + word.substring(1))
            })

            excelHeaders.push(colName.join(" "))
          }

          if(value == '' || value === null) {
            excelRows[index][key] = '-'
          }
        }
      })

      const worksheet = XLSX.utils.json_to_sheet(excelRows)

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "Semua Data")
      XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: "A1" });

      XLSX.writeFile(workbook, this.pageName + "_" + Date.now() + ".xlsx", { compression: true });

      this.isLoading = false
    },
    fetchVoucherCategories() {
      this.$http.get('/admin/v1/voucher_categories')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.voucherCategories = [
              {
                label: "Semua Tagging",
                value: "all"
              }
            ]

            data.data.forEach((item, index) => {
              this.voucherCategories.push({
                value: item,
                label: item
              })
            })
          }
        })
    },
    fetchVoucherData() {
      if(!this.voucherObject.status && !this.voucherObject.search && !this.voucherObject.category) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'Pilih salah satu kriteria pencarian terlebih dahulu',
          },
        })

        return
      }

      this.voucherData = {
        total_all_vouchers: 0,
        total_used: 0,
        total_unused: 0,
        total_sold: 0,
        total_unsold: 0,
        rows: []
      }

      this.isLoadingVoucher = true

      const requestData = {
        status: this.voucherObject.status,
        search: this.voucherObject.search,
        category: this.voucherObject.category,
      }

      this.$http.post('/admin/v1/report-voucher', requestData)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.voucherData.total_all_vouchers = currentData.total_all_vouchers
            this.voucherData.total_used = currentData.total_used
            this.voucherData.total_unused = currentData.total_unused
            this.voucherData.total_sold = currentData.total_sold
            this.voucherData.total_unsold = currentData.total_unsold
            this.voucherData.rows = currentData.vouchers
            
          }

          this.isLoadingVoucher = false
        })
    },
    fetchBooks() {
      this.isLoading = true

      this.$http.get('/admin/v1/books')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.books = []

            data.data.forEach((item, index) => {
              this.books.push({
                value: item.id,
                label: item.name
              })
            })
          }

          this.isLoading = false
        })
    },
    fetchData() {
      this.isLoading = true

      const requestData = {
        start_date: this.currentObject.start_date,
        end_date: this.currentObject.end_date,
        book_ids: this.currentObject.book_ids
      }

      this.$http.post('/admin/v1/report-transaction', requestData)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.series = currentData.chart_data
            this.chartOptions.xaxis.categories = currentData.dates
            this.bookSales = currentData.book_sales
            this.earnings = currentData.earnings
            this.totalUnits = currentData.total_units
            this.excelData = currentData.excel_data
            this.totalSuccessTransactions = currentData.total_success_transactions
            this.totalFailedTransactions = currentData.total_failed_transactions
            this.totalWaitingTransactions = currentData.total_waiting_transactions

          }

          this.isLoading = false

     })
    },
  },
  created() {
    // this.fetchData()
    this.fetchBooks()

    this.fetchData()

    this.fetchVoucherCategories()
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';

  .vgt-responsive {
    min-height: 180px;
  }

  .transaction-filter .v-select.vs--single.vs--searchable {
    min-width: 240px;
  }

  .date-filter {
    min-width: 220px;
  }

  .vs__selected-options {
    max-width: 240px;
    padding: 1px;
  }

  .vs--searchable {
    min-width: 240px;
  }
</style>